
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      open: false,
      currentBranchName: null,
      arrayOfWantedCategories: [],
    };
  },

  computed: {
    ...mapGetters({
      category: 'news/list/category',
      categories: 'news/list/categories',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    isDark() {
      return this.$q.dark.isActive;
    },
  },

  methods: {
    pushCategory(id) {
      const index =
        this.arrayOfWantedCategories.indexOf(id);
      if (index > -1) {
        this.arrayOfWantedCategories.splice(index, 1);
      } else {
        this.arrayOfWantedCategories.push(id);
      }

      console.log(this.open);
    },
    ...mapActions({
      changeCategory: 'news/list/changeCategory',
      doFetch: 'news/list/doFetchCategory',
      doFetchFilter: 'news/list/doFetch',
    }),
    changeCategoryy(id) {
      this.changeCategory(id);
      this.currentBranchName = this.categories.find(
        (item) => item.id === this.category,
      ).name;
    },
    toggle() {
      if (this.open) {
        this.open = false;
        this.arrayOfWantedCategories = [];
      } else {
        this.open = true;
      }
    },
  },
  async created() {
    await this.doFetch();
    console.log('cato', this.categories);
    if (this.categories.length > 0) {
      this.currentBranchName = this.categories.find(
        (item) =>
          item.id === this.category ||
          this.categories[0].id,
      );
      this.currentBranchName =
        this.currentBranchName['name'];
    }
  },
};
