
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { NewsModel } from '@/modules/news/news-model';
import customeCategory from '@/modules/news/components/category.vue';

export default {
  name: 'app-news-list-table',
  data() {
    return {
      showMenu: true,
      search: undefined,
      arrayFilter: [],
      filter: 'Football',
      page: parseInt(this.$route.query.page) || 1,
      action: '',
    };
  },
  components: {
    customeCategory,
  },
  computed: {
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      rows: 'news/list/rows',
      mountedTable: 'news/list/mountedTable',
      loading: 'news/list/loading',
      category: 'news/list/category',
      currentLanguageCode: 'layout/currentLanguageCode',
      pagination: 'news/list/pagination',
    }),
    paginationPage() {
      return this.page - 1 || 0;
    },
  },
  watch: {
    async page() {
      this.$router.push({
        path: 'news',
        query: { page: this.page },
      });

      await this.doFetch({
        filterArray: this.arrayFilter,
        action: this.action,
        currentPage: this.paginationPage,
      });
    },

    async arrayFilter() {
      await this.doFetch({
        filterArray: this.arrayFilter,
        action: 'next',
        currentPage: 0,
      });
    },
    // async category() {
    //   await this.doFetch({
    //     action: this.action,
    //     currentPage: this.paginationPage,
    //   });
    // }
  },
  methods: {
    ...mapActions({
      doFetch: 'news/list/doFetch',
      setMountedTable: 'news/list/setMountedTable',
    }),
    handleFilter(array) {
      this.arrayFilter = array;
    },
    next() {
      this.page++;
      this.action = 'next';
    },
    prev() {
      this.page--;
      this.action = 'prev';
    },

    doFilterNews(value) {
      // filter=='Basketball'
      this.filter = value;
      // console.log(this.filter);
    },

    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = NewsModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
  },
  async created() {
    await this.doFetch({
      filterArray: [],
      action: 'next',
      currentPage: this.paginationPage,
    });
  },
};
