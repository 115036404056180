
import NewsListTable from '@/modules/news/components/news-list-table.vue';
import NewsListFilter from '@/modules/news/components/news-list-filter.vue';

import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      drawer: false,
      search: undefined,
      group: [],
      filter: 'Football',
      options: [
        { label: 'Battery too low', value: 'bat' },
        { label: 'Friend request', value: 'friend', color: 'green' },
        { label: 'Picture uploaded', value: 'upload', color: 'red' }
      ]
    }
  },
  components: {
    [NewsListTable.name]: NewsListTable,
    [NewsListFilter.name]: NewsListFilter
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
      isMobile: 'layout/isMobile',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro'
    }),
  },
  methods: {
    doFilterNews(value) {
      // filter=='Basketball'
      this.filter = value
      // console.log(this.filter);
    }
  }
}
