
import NewsListFilterItem from '@/modules/news/components/news-list-filter-item.vue';

export default {
    name:'app-news-list-filter',
    data(){
        return{
            filter:['Football'],
        }
    },
    components:{
      [NewsListFilterItem.name]: NewsListFilterItem
    },
    methods:{
        doFilterNews(value){
        // filter=='Basketball'
        // this.filter = value
        // console.log(this.filter);
        if(this.filter.includes(value)){
            let index = this.filter.indexOf(value)
            this.filter.splice(index,1)
        }
        else{
            this.filter.push(value)
        }
        
      },
      doFilterAction(){
          console.log('filter action')
      }
    }
}
